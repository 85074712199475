/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Wzmocnienie muru z ciepłych pustaków keramzytobetonowych czy bloczków z betonu komórkowego pozwala wyraźnie zwiększyć wytrzymałość muru w miejscach, gdzie występuje koncentracja naprężeń lub skok obciążeń na szerokości ściany. Takimi miejscami są przede wszystkim fragmenty ścian pod oknami, miejsca między nadprożem a wieńcem, czy też filarki murowe, na których opierają się belki żelbetowe lub stalowe. Siatka Murfor Compact umożliwia zastosowanie nawet w takich murach ciepłego pustaka keramzytobetonowego zamiast wprowadzania lokalnie trzpienia żelbetowego lub fragmentu muru o lepszych parametrach wytrzymałościowych, ale w konsekwencji dużo gorszej izolacyjności termicznej."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/img/murfor-compact.jpg",
    alt: "Zbrojenie murowe Murfor Compact"
  })), "\n", React.createElement(_components.p, null, "Jeżeli nie wiesz, czy w Twoim projekcie należy zastosować siatki Murfor Compact lub dedykowane zbrojenie stalowe Czamaninek, to nie musisz się martwić. Do każdego zamówienia otrzymasz od nas model 3D oraz projekt ofertowy, w którym zawarte są szczegółowe instrukcje stosowania siatek zbrojeniowych. Murfor Compact można stosować zamiennie ze zbrojeniem murowym Czamaninek. Dla ścian z pustaków AKU K5 i AKU K4 zalecamy stosowanie dwóch siatek Murfor Compact I-50 ułożonych równolegle i symetrycznie w spoinie. W przypadku ścian AKU K2 i AKU K3 również można zastosować podwójną siatkę I-50 lub pojedynczą I-100. Z kolei w rzadkich przypadkach, gdy zajdzie konieczność dozbrojenia obciążonej ściany Termo 12 lub Termo 10 – wystarczy pojedynczo ułożona siatka Murfor Compact I-50."), "\n", React.createElement(_components.p, null, "Zbrojenie murowe Murfor Compact ma grubość zaledwie 1.7 mm, dzięki czemu znakomicie sprawdzi się w spoinach cienkowarstwowych. Tak radykalne zmniejszenie średnicy prętów zbrojeniowych tworzących siatkę było możliwe, dzięki zastosowaniu stali o granicy plastyczności ponad 1770 MPa. Jest to wartość, którą osiągają sploty sprężające w kilkudziesięciometrowych belkach sprężonych! Zbrojenie murowe Murfor Compact gwarantuje przeniesienie znacznych naprężeń rozciągających sprawiając tym samym, że trwałość muru i jego odporność na zarysowania znacząco wzrasta, a parametry termiczne pozostają na charakterystycznym dla linii produktów AKU wysokim poziomie."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
